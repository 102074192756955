import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useBitrix } from "../BitrixProvider";

import "./blurb-category.css";

export const BlurbCategory = () => {
  const { i18n } = useTranslation();
  const bitrix = useBitrix();
  const category = useSelector((state) => state.catalog.main.settings.sect);

  const [banner, setBanner] = useState({});

  const getBanner = () => {
    bitrix.content
      .getBlurbCategory(category)
      .then((res) => {
        if (res.ID) {
          setBanner(res);
        } else {
          setBanner({});
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    if (category) {
      getBanner();
    } else {
      setBanner({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  if (Object.keys(banner).length > 0) {
    return (
      <div className="container">
        <div className="row no-gutters blurb-category-wrap">
          <div className="col-12">
            <Link to={banner.LINK}>
              <img
                src={banner.LANG[i18n.resolvedLanguage]}
                alt={banner.ID}
                className="img-fluid"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return <Fragment />;
};
